<template>
  <div>
    <el-collapse-transition>
      <!--搜索框-->
      <TableSearch v-show="showSearch" :query-params="queryParams" @search="handleSearch" @reset="handleReset">
        <el-form :model="queryParams" ref="queryForm" label-suffix=":" label-width="100px">

          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
              <el-form-item prop="content" label="留言内容">
                <el-input v-model="queryParams.content" placeholder="请输入留言内容" clearable/>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
              <el-form-item label="留言日期">
                <el-date-picker
                    v-model="dateRange"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </TableSearch>
    </el-collapse-transition>

    <div>
      <el-button style="width: 80px" size="mini" type="primary" @click="showSearch = !showSearch">{{showSearch ? '隐藏搜索' : '显示搜索'}}</el-button>
    </div>

    <!--列表-->
    <div>
      <el-table
          :data="tableData"
          stripe
          style="width: 100%; font-size: 15px"
          size="medium"
          @sort-change="handleSortChange"
          :default-sort = "{prop: 'createTime', order: 'descending'}"
      >
        <el-table-column type="index" width="50"/>

        <el-table-column prop="nickname" label="用户名" width="200"/>

        <el-table-column prop="content" label="留言内容" show-overflow-tooltip />

        <el-table-column prop="createTime" label="留言时间" width="200" sortable="custom"/>

        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" class="color-danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <pagination
          v-show="queryParams.total>0"
          :total="queryParams.total"
          :page.sync="queryParams.curPage"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
      />
    </div>

  </div>
</template>

<script>
import TableSearch from "@/components/TableSearch/TableSearch.vue";
import Pagination from "@/components/Pagination/index.vue";
import CommonDialog from "@/components/CommonDialog.vue";

export default {
  name: "index",
  components: {CommonDialog, Pagination, TableSearch},
  data() {
    return {
      queryParams: {
        total: 0,
        curPage: 1,
        pageSize: 10,
        content: '',
        sortColumn: '',
        sortOrder: '',
      },
      tableData: [],
      showSearch: false,
      dialogInfo: {
        title: '',
        type: '',
        disabled: false,
      },
      dateRange: [],
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    getList() {
      this.$api.leaveWord.page(this.$addDateRange(this.queryParams, this.dateRange)).then(res => {
        this.tableData = res.list
        this.queryParams.total = res.total
        this.queryParams.pages = res.pages
      })
    },

    handleSortChange(e) {
      this.queryParams = this.$handleSort(this.queryParams, e)
      this.getList()
    },

    handleDelete(row) {
      this.$dialog({
        type: 'danger',
        content: `确认删除该条留言吗？`
      }).then(() => {
        this.$api.leaveWord.del(row.id).then(() => {
          this.getList()
          this.$message.success('删除成功')
        })
      })
    },

    handleSearch() {
      this.queryParams.curPage = 1
      this.getList()
    },

    handleReset() {
      this.dateRange = []
      this.$resetForm('queryForm')
      this.$nextTick(() => {
        this.handleSearch()
      })
    },
  }
}
</script>

<style scoped>

</style>
